<template>
    <cr-delete-confirmation-dialog id="deleteSavedEnvironmentDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="delete-saved-environment-dialog">
        <cr-html :value="t('DELETE_SAVED_ENVIRONMENT_CONFIRMATION', {familyName: catalogFamily?.name})"/>
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { ICatalogFamily } from '@cyber-range/cyber-range-api-catalog-client';
import { useCatalogStore } from '../../../stores/catalogStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    catalogFamily?: ICatalogFamily;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    await useCatalogStore().deleteCatalogFamily(props.catalogFamily!.id);
    emit('confirm');
}
</script>