<template>
    <a href="#main" class="skip-to-main-link cr-p2" data-testing="page-skip-to-main">{{ t('PAGE_SKIP_TO_MAIN_CONTENT') }}</a>
    <instructor-survey-header-notification />
    <header>
        <Header />
    </header>
    <main>
        <cr-container class="cr-height-100">
            <cr-row class="cr-height-100">
                <cr-col v-if="canNavigateOutsideCourse" xs12 sm12 md3 lg2 xl2 class="left-column">
                    <Sidebar />
                </cr-col>
                <cr-col class="main-column" id="main">
                    <router-view />
                </cr-col>
            </cr-row>
            <agreements v-if="showAgreementsDialogs" @confirm="onAgreementsConfirmed" @cancel="onAgreementsCancelled" :pendingAgreements="pendingAgreements"/>
            <template v-else-if="!fetchingAgreements">
                <instructor-survey-dialog/>
                <instructor-mini-survey-dialog/>
            </template>
            <errors-dialog />
            <impersonation-bar/>
            <session-expiring-dialog/>
            <cr-announcer/>
        </cr-container>
    </main>
</template>

<style scoped>
main {
    flex-grow: 1;
}
.skip-to-main-link
{
    position: absolute;
    height: 2rem;
    left: 50%;
    background: var(--cr-primary);
    color: var(--cr-primary-text);
    transform: translateY(-100%);
    transition: transform 0.4s;
    z-index: 10;
}
.skip-to-main-link:focus
{
    transform: translateY(0%);
}
.left-column
{
    min-width: 250px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.main-column
{
    display: flex;
    flex-direction: column;
    padding: 1.5rem var(--cr-main-column-side-padding);
    background-color: var(--cr-main-background-color);
    min-width: 0;
}
@media (max-width: 576px)
{
    .main-column {
        --cr-main-column-side-padding: var(--cr-main-column-side-xs-padding);
    }
}
:deep(.page-title)
{
    font-size:  var(--cr-page-title-font-size);
    margin-top: 1.5rem; 
    min-height: 1.5rem; /* Eliminate page layout instability during breadcrumb loading */
}
:deep(.cr-breadcrumb)
{
    min-height: 1.5rem; /* Eliminate page layout instability during breadcrumb loading */
}
</style>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAgreementStore } from '../stores/agreementStore';
import { useSessionStore } from '../stores/sessionStore';
import Agreements from './agreements/Agreements.vue'
import { IAgreement } from '@cyber-range/cyber-range-api-agreement-client';
import Header from './Header.vue';
import Sidebar from './sidebar/Sidebar.vue';
import ImpersonationBar from './impersonation/ImpersonationBar.vue';
import ErrorsDialog from './ErrorsDialog.vue';
import SessionExpiringDialog from './SessionExpiringDialog.vue';
import { useAuthorizationStore } from '../stores/authorizationStore';
import InstructorSurveyHeaderNotification from './instructorSurvey/InstructorSurveyHeaderNotification.vue';
import InstructorSurveyDialog from './instructorSurvey/InstructorSurveyDialog.vue';
import InstructorMiniSurveyDialog from './instructorSurvey/InstructorMiniSurveyDialog.vue';

const { t } = useI18n();

const canNavigateOutsideCourse = useAuthorizationStore().canNavigateOutsideCourse();

const pendingAgreements = ref<IAgreement[]>([]);
const showAgreementsDialogs = ref<boolean>(false);
const fetchingAgreements = ref(true);

const refreshAgreements = async () =>
{
    pendingAgreements.value = await (useAgreementStore().fetchMyPendingAgreements());
    showAgreementsDialogs.value = pendingAgreements.value.length > 0;
    fetchingAgreements.value = false;
}

const onAgreementsConfirmed = async ()=> 
{
    showAgreementsDialogs.value = false;
    await refreshAgreements();
}

const onAgreementsCancelled = async ()=>
{
    useSessionStore().logout();
    showAgreementsDialogs.value = false;
}

onMounted(async () => 
{
    await refreshAgreements();
});
</script>
