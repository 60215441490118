<template>
    <cr-col xs12 sm12 md12 lg12 xl4>
        <cr-image v-if="catalogFamily.logo" :src="catalogFamily.logo" alt="" class="cr-p4" min-height="10rem" max-height="18rem" width="100%" cover="true" data-testing="saved-environment-info-logo"/>
    </cr-col>

    <cr-col xs12 sm12 md12 lg12 xl8>
        <read-section :label="t('SAVED_ENVIRONMENT_INFO_SUMMARY')" data-testing="saved-environment-info-summary">
            <p class="exercise-group-info-section">{{ catalogFamily.description }}</p>
        </read-section>
        <read-section :label="t('SAVED_ENVIRONMENT_INFO_DESCRIPTION')" data-testing="saved-environment-info-description">
            <p class="exercise-group-info-section">{{ catalogEntry.description }}</p>
        </read-section>

        <read-detail-section class="cr-mt5">
            <read-detail-section-item v-if="operatingSystems.length > 0" :label="t('SAVED_ENVIRONMENT_INFO_OS')" data-testing="saved-environment-info-os">
                <div v-for="os in operatingSystems">
                    {{ os.name }} {{ os.version }}
                </div>
            </read-detail-section-item>

            <read-detail-section-item v-if="catalogFamily.version" :label="t('SAVED_ENVIRONMENT_INFO_VERSION')" data-testing="saved-environment-info-version">
                {{ catalogFamily.version }}
            </read-detail-section-item>

            <read-detail-section-item v-if="organizationName" :label="t('SAVED_ENVIRONMENT_INFO_ORGANIZATION')" data-testing="saved-environment-info-organization">
                {{ organizationName }}
            </read-detail-section-item>

            <read-detail-section-item :label="t('SAVED_ENVIRONMENT_INFO_CREATION')" data-testing="saved-environment-info-creation">
                {{ useCalendar().toHuman(catalogFamily.createdTimestamp) }}
            </read-detail-section-item>

            <read-detail-section-item :label="t('SAVED_ENVIRONMENT_INFO_MODIFICATION')" data-testing="saved-environment-info-modification">
                {{ useCalendar().toHuman(catalogEntry.modifiedTimestamp) }}
            </read-detail-section-item>

            <read-detail-section-item :label="t('SAVED_ENVIRONMENT_INFO_STATUS')" data-testing="saved-environment-info-status">
                <catalog-entry-status-chip :status="catalogEntry.status"/>
            </read-detail-section-item>
        </read-detail-section>
    </cr-col>
</template>

<style scoped>
.saved-environment-info-section {
    white-space: pre-wrap;
}
</style>

<script setup lang="ts">
import { ICatalogEntry, ICatalogFamily } from '@cyber-range/cyber-range-api-catalog-client';
import { useI18n } from 'vue-i18n';
import ReadSection from '../layouts/sections/ReadSection.vue';
import ReadDetailSection from '../layouts/sections/ReadDetailSection.vue';
import ReadDetailSectionItem from '../layouts/sections/ReadDetailSectionItem.vue';
import { computed } from 'vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import { useCalendar } from '../../composables/useCalendar';
import CatalogEntryStatusChip from './CatalogEntryStatusChip.vue';


const props = defineProps<
{
    catalogFamily: ICatalogFamily;
    catalogEntry: ICatalogEntry;
}>();

const { t } = useI18n();

const operatingSystems = computed(() => props.catalogEntry?.metadata?.tags?.operatingSystems || []);
const organizationName = computed(() => useOrganizationStore().currentOrganizationName);
</script>