<template>
    <cr-confirmation-dialog id="shareSavedEnvironmentDialog" :title="t('SHARE_SAVED_ENVIRONMENT_TITLE')" @confirm="onConfirmed" @shown="onShown" :disabled="isLoading" :form-instruction="true" data-testing="share-saved-environment-dialog">
        <div class="cr-mb4" data-testing="share-saved-environment-description">{{ t('SHARE_SAVED_ENVIRONMENT_DESCRIPTION') }}</div>
        <cr-external-link :href="kbLink" data-testing="share-saved-environment-kb-link">{{ t('SHARE_SAVED_ENVIRONMENT_KB_LINK_TEXT') }}</cr-external-link>
        <form-section :form-instruction="true" class="cr-mt4">
            <cr-text-field v-model="emails" :label="t('SHARE_SAVED_ENVIRONMENT_EMAILS_LABEL')" type="email" required multiple data-testing="share-saved-environment-emails"/>
            <cr-select v-model="selectedAccessType" :label="t('SHARE_SAVED_ENVIRONMENT_SHARE_TYPE_SELECT_LABEL')" :items="accessTypeSelectItems" required data-testing="share-saved-environment-access-type-select"/>
        </form-section>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import FormSection from '../../layouts/sections/FormSection.vue';
import { useI18n } from 'vue-i18n';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { CatalogFamilyAccessType } from '@cyber-range/cyber-range-api-catalog-client';
import { useKbStore } from '../../../stores/kbStore';
import { useCatalogStore } from '../../../stores/catalogStore';

const props = defineProps<{
    catalogFamilyId: string
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const kbLink = useKbStore().sharingSavedEnvironment();

const emails = ref("");
const selectedAccessType = ref(CatalogFamilyAccessType.Linked);
const accessTypeSelectItems = [
    {id: CatalogFamilyAccessType.Linked, name: t('SHARE_SAVED_ENVIRONMENT_LINKED_LABEL')},
    {id: CatalogFamilyAccessType.Copied, name: t('SHARE_SAVED_ENVIRONMENT_COPIED_LABEL')},
]

const onConfirmed = async () =>
{
    const recipients = emails.value.split(",").map(e => e.trim()).filter(e => !!e);
    await useCatalogStore().createCatalogFamilyAccesses(props.catalogFamilyId, selectedAccessType.value, recipients);
    emit('confirm');
}

const onShown = async () =>
{
    emails.value = "",
    selectedAccessType.value = CatalogFamilyAccessType.Linked;
}
</script>