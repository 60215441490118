<template>
    <read-layout :title="pageTitle" :subtitles="{ bottom: bottomSubtitle }" :breadcrumbs="breadcrumbs" :ready="isReady">
        <template #top>
            <cr-row>
                <saved-environment-info :catalog-family="catalogFamily!" :catalog-entry="catalogEntry!"/>
            </cr-row>
        </template>

        <template #controls>
            <read-control-item>
                <cr-button :to="Route.Todo" :disabled="disableUpdateSavedEnvironmentControl" outlined data-testing="edit-saved-environment-button">
                    <cr-icon>bi-pencil</cr-icon>
                    {{ t('SAVED_ENVIRONMENT_EDIT_SAVED_ENVIRONMENT') }}
                </cr-button>
            </read-control-item>
            <read-control-item>
                <cr-button :to="Route.Todo" :disabled="disableDeploySavedEnvironmentControl" outlined data-testing="deploy-saved-environment-button">
                    <cr-icon>bi-box-arrow-right</cr-icon>
                    {{ t('SAVED_ENVIRONMENT_DEPLOY_SAVED_ENVIRONMENT') }}
                </cr-button>
            </read-control-item>
            <read-control-item>
                <cr-button :disabled="disableDeleteSavedEnvironmentControl" dialog="#deleteSavedEnvironmentDialog" outlined data-testing="delete-saved-environment-button">
                    <cr-icon>bi-trash</cr-icon>
                    {{ t('SAVED_ENVIRONMENT_DELETE_SAVED_ENVIRONMENT') }}
                </cr-button>
            </read-control-item>
            <delete-saved-environment-dialog :catalog-family="catalogFamily" @confirm="onDeleteSavedEnvironmentConfirmed"/>
        </template>

        <template #bottom >
            <saved-environment-access-table ref="saved-environment-access-table" :catalog-family-id="catalogFamilyId"/>
        </template>

        <template #bottomControls>
            <read-control-item>
                <cr-button :disabled="disableShareSavedEnvironmentControl" dialog="#shareSavedEnvironmentDialog" outlined data-testing="share-saved-environment-button">
                    <cr-icon>bi-share</cr-icon>
                    {{ t('SAVED_ENVIRONMENT_SHARE_SAVED_ENVIRONMENT') }}
                </cr-button>
            </read-control-item>
            <read-control-item>
                <cr-button v-if="showAddOwnerControl" :to="Route.Todo" outlined data-testing="add-owner-button">
                    <cr-icon>bi-person-plus</cr-icon>
                    {{ t('SAVED_ENVIRONMENT_ADD_OWNER') }}
                </cr-button>
            </read-control-item>
            <share-saved-environment-dialog :catalog-family-id="catalogFamilyId" @confirm="onShareSavedEnvironmentConfirmed"/>
        </template>
    </read-layout>
</template>

<style scoped>
</style>

<script setup lang="ts">
import { CatalogFamily, ICatalogEntry, ICatalogFamily } from '@cyber-range/cyber-range-api-catalog-client';
import { useI18n } from 'vue-i18n';
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { computed, ref, useTemplateRef, watch } from 'vue';
import { useCatalogStore } from '../../stores/catalogStore';
import savedEnvironmentInfo from './SavedEnvironmentInfo.vue';
import { BreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import Route from '../../routers/route';
import ReadLayout from '../layouts/ReadLayout.vue';
import { useOrganizationStore } from '../../stores/organizationStore';
import SavedEnvironmentAccessTable from './SavedEnvironmentAccessTable.vue';
import ReadControlItem from '../layouts/sections/ReadControlItem.vue';
import { useSavedEnvironmentBreadcrumbs } from '../../composables/useSavedEnvironmentBreadcrumbs';
import { useSessionStore } from '../../stores/sessionStore';
import { useCourseStore } from '../../stores/courseStore';
import { ICourse } from '@cyber-range/cyber-range-api-user-client';
import DeleteSavedEnvironmentDialog from './dialogs/DeleteSavedEnvironmentDialog.vue';
import { useWhoseSavedEnvironment } from '../../composables/useWhoseSavedEnvironment';
import { useRouter } from 'vue-router';
import ShareSavedEnvironmentDialog from './dialogs/ShareSavedEnvironmentDialog.vue';
import { useNotificationStore } from '../../stores/notificationStore';

const props = defineProps<
{
    catalogFamilyId: string;
}>();

const activityTable = useTemplateRef("saved-environment-access-table")

const catalogFamily = ref<ICatalogFamily>();
const catalogEntry = ref<ICatalogEntry>();
const canCreateExerciseGroupCourses = ref<ICourse[]>();
const deployableCourses = ref<ICourse[]>();
const organizationIdExerciseGroupLimitMap = new Map<string, number>();

const currentUserId = useSessionStore().session?.userId;

const router = useRouter();
const whoseSavedEnvironment = useWhoseSavedEnvironment();
const breadcrumbs = useSavedEnvironmentBreadcrumbs(computed(() => [
    new BreadcrumbItem(Route.SavedEnvironments),
    new BreadcrumbItem({...Route.SavedEnvironment, text: catalogFamily?.value?.name})
]));


const { t } = useI18n();

const isReady = computed(() => !!catalogFamily.value && !!catalogEntry.value);

watch(()=>props.catalogFamilyId, async () =>
{
    catalogFamily.value = new CatalogFamily(await useCatalogStore().getCatalogFamily(props.catalogFamilyId));
    await useOrganizationStore().fetchOrganizationNameAndLogo(catalogFamily.value?.organizationId);
    const defaultEntryId = catalogFamily.value.defaultCatalogEntry?.id;
    if (defaultEntryId)
    {
        catalogEntry.value = await useCatalogStore().fetch(defaultEntryId);
    }

    canCreateExerciseGroupCourses.value = (await useCourseStore().fetchAllCourses()).filter(c => authorizationStore.canCreateExerciseGroup(c.id));
    for (const course of canCreateExerciseGroupCourses.value)
    {
        const orgId = course.organizationId;
        if (!organizationIdExerciseGroupLimitMap.has(orgId))
        {
            const organization = await useOrganizationStore().getOrganization(orgId);
            organizationIdExerciseGroupLimitMap.set(orgId, organization.limits.maxExerciseGroupsPerCourse);
        }
    }
    deployableCourses.value = canCreateExerciseGroupCourses.value.filter(c => c.statistics.numberOfExercises < (organizationIdExerciseGroupLimitMap.get(c.organizationId) || 0) )
}, { immediate: true });

const pageTitle = computed(() => catalogFamily.value?.name || t('SAVED_ENVIRONMENT_DEFAULT_TITLE'));
const bottomSubtitle = computed(() => useAuthorizationStore().canViewCatalogFamilyAccesses(catalogFamily.value?.organizationId) ? t("SAVED_ENVIRONMENT_VISIBLE_ACCESS"): t("SAVED_ENVIRONMENT_PEOPLE_WITH_ACCESS"));

const authorizationStore = useAuthorizationStore();
const catalogFamilyOwnedByMe = computed(() => catalogFamily.value?.userId && currentUserId === catalogFamily.value.userId);
const disableUpdateSavedEnvironmentControl = computed(() => !(authorizationStore.canUpdateAnyCatalogFamily() || catalogFamilyOwnedByMe.value));
const disableDeploySavedEnvironmentControl = computed(() => !deployableCourses.value?.length);
const disableDeleteSavedEnvironmentControl = computed(() => !(authorizationStore.canDeleteAnyCatalogFamily() || catalogFamilyOwnedByMe.value));
const disableShareSavedEnvironmentControl = computed(() => !(authorizationStore.canCreateCatalogFamilyAccesses(catalogFamily.value?.organizationId) || catalogFamilyOwnedByMe.value));
const showAddOwnerControl = computed(() => authorizationStore.canAddCatalogFamilyOwner(catalogFamily.value?.organizationId));

const onDeleteSavedEnvironmentConfirmed = async () =>
{
    await router.push(whoseSavedEnvironment.value.topRoute);
}

const onShareSavedEnvironmentConfirmed = async () =>
{
    useNotificationStore().notify(t('SHARE_SAVED_ENVIRONMENT_SUCCESS_NOTIFICATION'));
    await activityTable.value?.reload();
}
</script>