import { defineStore } from "pinia";
import { useApiClientStore } from "./apiClientStore";
import { IFile } from "@cyber-range/cyber-range-api-file-client";

export const useFileStore = defineStore('fileStore', 
{
    state: () => 
    ({
       
    }),

    actions:
    {
        async uploadFile(file: Partial<IFile>, data: { size: number, [key: string]: any }, options?: {background?:boolean}): Promise<string>
        {
            let fileApiClient = options?.background ? useApiClientStore().backgroundFileApiClient : useApiClientStore().fileApiClient;

            return (await fileApiClient.upload(file, data)).id;
        },

        async getDownloadUrl(fileId: string, options?: {background?:boolean}): Promise<string>
        {
            let fileApiClient = options?.background ? useApiClientStore().backgroundFileApiClient : useApiClientStore().fileApiClient;

            const { publicUrl } = await fileApiClient.getOneDownloadUrl(fileId);

            return publicUrl;
        }
    }
})