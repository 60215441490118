<template>
    <cr-radio tag="fieldset" v-model="selectedValue" :name="question.id" :required="question.required">
        <legend><cr-html :value="question.text" mode="discard"/></legend>
        <div class="radio-item-holder">
            <cr-radio-item v-for="(answer, i) in question.answers" :value="answer.id" >
                <span>{{ answer.text }}</span>
                <span v-if="i === 0" class="cr-caption">{{ t('INSTRUCTOR_MINI_SURVEY_NPS_VERY_UNLIKELY') }}</span>
                <span v-else-if="i === question.answers.length - 1" class="cr-caption">{{ t('INSTRUCTOR_MINI_SURVEY_NPS_VERY_LIKELY') }}</span>
            </cr-radio-item>
        </div>
    </cr-radio>
</template>

<style scoped>
.radio-item-holder {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    margin-bottom: 1rem;
}
:deep(.cr-radio-input) {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    white-space: nowrap;
}
:deep(.cr-radio-input:focus + label) {
    outline: 1px solid var(--cr-primary);
}
:deep(.cr-radio-input:checked + label) {
    background-color: var(--cr-primary);
    color: white;
}
:deep(.cr-radio-item) {
    padding: 0;
    width: 100%;
    min-height: unset;
    aspect-ratio: 1 / 1;
}
:deep(.cr-radio-label) {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 !important;
    text-align: center;
    border: 1px solid #7D7D7D;
    background-color: #F8F8F8;
    border-radius: 5px;
    color: #333333;
}
.cr-radio-label > span:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.cr-radio-label > span.cr-caption {
    position: absolute;
    top: calc(100% + 4px);
    white-space: nowrap;
}
.cr-radio-item:first-of-type > .cr-radio-label > span.cr-caption {
    left: 0;
}
.cr-radio-item:last-of-type > .cr-radio-label > span.cr-caption {
    right: 0;
}
</style>

<script setup lang="ts">
import { IQuestion } from '@cyber-range/cyber-range-api-survey-client';
import { ISubmissionResponseAnswer } from '@cyber-range/cyber-range-api-survey-client/dist/interfaces/iSubmissionResponseAnswer';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{ question: IQuestion }>();

const answerModelValue = defineModel<ISubmissionResponseAnswer>('answer');

const selectedValue = computed({
    get: () => answerModelValue.value?.id || '',
    set: (id: string) =>
    {
        const value = props.question.answers.find(answer => answer.id == id)?.text || ''
        answerModelValue.value = { id, value };
    }
});
</script>
