import Config from '../config';
import { defineStore } from 'pinia';

export const useKbStore = defineStore('kbStore', 
{
    actions: 
    {
        home():string
        {
            return Config.KB_BASE_URL;
        },
        invitationCode():string
        {
            return new URL("/getting-started/instructors-inviting-users.html#generating-an-invitation-code", this.home()).toString();
        },
        selfRegistration():string
        {
            return new URL("/getting-started/students-how-to-join-course.html#joining-a-course-via-an-invitation-code", this.home()).toString();
        },
        reservedEnrollments():string
        {
            return new URL("/features/pool-model.html#setting-reserved-enrollments", this.home()).toString();
        },
        exercisePersistence():string
        {
            return new URL("/features/persistent-nonpersistent-environment.html", this.home()).toString();
        },
        environmentTimeRemaining(): string
        {
            return new URL("/features/persistent-nonpersistent-environment.html#environment-time-remaining", this.home()).toString();
        },
        clockOutOfSync(): string
        {
            return new URL("/troubleshooting/cant-login.html#pclaptop-system-clock-time-date-skew", this.home()).toString();
        },
        linkingCanvas(): string
        {
            const path = Config.IS_VACR ? '/features/virginia-linking-canvas.html' : "/features/us-linking-canvas.html"
            return new URL(path, this.home()).toString();
        },
        configuringCanvas(): string
        {
            const path = Config.IS_VACR ? '/features/virginia-canvas-integration.html' : "/features/us-canvas-integration.html"
            return new URL(path, this.home()).toString();
        },
        configuringCanvasOrganizationApplicationInformation(): string
        {
            const url = new URL(this.configuringCanvas());
            url.hash = '#2-setting-up-the-cyber-range';
            return url.toString();
        },
        interTeamConnectivity(): string
        {
            // TO DO - ADD URL HERE WHEN FEATURE ARTICLE IS COMPLETE
            return new URL("/features/avoiding-common-issues-copying-exercises.html", this.home()).toString();
        },
        directPayHelp(): string
        {
            return new URL("/features/user-direct-pay.html", this.home()).toString();
        },
        organizationLimits(): string|undefined
        {
            return Config.IS_VACR
                ? undefined
                : new URL('/admin/organization-limits.html', this.home()).toString();
        },
        sharingSavedEnvironment(): string
        {
            return new URL("/features/managing-shared-environments#sharing-shared-environment", this.home()).toString();
        },
    }
})