<template>
    <cr-app class="cr-app">
        <router-view :key="fullPath"/>
        <!-- Notifications are outside router view so notification visibility doesn't reset when navigating to a new page -->
        <div class="notification-area" data-testing="notification-area">
            <cr-toast class="cr-mt3 cr-mb0" v-for="[key, value] in notifications" :key="key" data-testing="notification" >{{ value }}</cr-toast>
        </div>
    </cr-app>
</template>

<style>
a
{
    text-decoration: none !important;
    color: var(--cr-text);
}
a:hover
{
    color: var(--cr-text);
}
.cr-external-link
{
    color: var(--cr-primary-link);
}
.cr-checkbox-label
{
    padding-top: var(--cr-check-box-label-top-padding); /* support for adapting --bs-form-check spacing to work with console font family */
}
.cr-radio-label
{
    padding-top: var(--cr-radio-button-label-top-padding); /* support for adapting --bs-form-check spacing to work with console font family */
}
.notification-area
{
    position: fixed;
    bottom: calc(var(--cr-main-column-side-padding) / 2);
    right: calc(var(--cr-main-column-side-padding) / 2);
    width: var(--cr-right-handside-control-width);
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router';
import { useNotificationStore } from './stores/notificationStore';
const route = useRoute();
const fullPath = computed(()=> 
{
    return `${route?.fullPath}_${route?.meta?.navigationId}`;
});

const notifications = computed(() => [...useNotificationStore().notifications.entries()].reverse())
</script>

<style scoped>
.cr-app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
</style>
