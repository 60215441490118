<template>
    <cr-delete-confirmation-dialog @confirm="onConfirmed" :confirm-text="t('SAVED_ENVIRONMENTS_REMOVE_MY_ACCESS_CONFIRM_TEXT')" :disabled="isLoading" data-testing="remove-my-access-saved-environment-dialog">
        <cr-html :value="t('SAVED_ENVIRONMENTS_REMOVE_MY_ACCESS_CONFIRMATION', {familyName: catalogFamily?.name})"/>
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { ICatalogFamily } from '@cyber-range/cyber-range-api-catalog-client';
import { useCatalogStore } from '../../../stores/catalogStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    catalogFamily?: ICatalogFamily;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.catalogFamily?.id)
    {
        await useCatalogStore().removeMyAccess(props.catalogFamily.id);        
    }
    emit('confirm');
}
</script>