<template>
    <cr-confirmation-dialog @confirm="onConfirmed" :title="t('SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_CONFIRMATION_DIALOG_TITLE')" :disabled="isLoading" data-testing="re-share-environment-access-dialog">
        <cr-html :value="t('SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_CONFIRMATION_DIALOG_MESSAGE', {accessEmail: catalogFamilyAccess?.email})"/>
        <br/>
        <br/>
        <p>{{ t('SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_CONFIRMATION_DIALOG_EXPLANATION') }}</p>
    </cr-confirmation-dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { ICatalogFamilyAccess } from '@cyber-range/cyber-range-api-catalog-client';
import { useCatalogStore } from '../../../stores/catalogStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    catalogFamilyAccess?: ICatalogFamilyAccess;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const onConfirmed = async ()=>
{
    if(props.catalogFamilyAccess?.id)
    {
        await useCatalogStore().reShareCatalogFamilyAccess(props.catalogFamilyAccess.id);        
    }
    emit('confirm');
}
</script>