<template>
    <div v-if="showCountDown" class="countdown-container">
        <cr-image src="/survey/sad-corgi.svg" alt="" max-width="8rem" data-testing="instructor-survey-expiration-countdown-image"/>
        <mark class="cr-plr3 cr-ptb0" data-testing="instructor-survey-expiration-countdown-text"><b class="countdown-text">{{ countdownText }}</b></mark>
    </div>
</template>

<style scoped>
.countdown-container
{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.countdown-text
{
    font-size: 1.4rem;
}
mark
{
    background-color: var(--cr-highlight-background-color);
}
</style>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useInstructorSurveyStore } from '../../stores/instructorSurveyStore';
import Config from '../../config';
const { t } = useI18n()

const daysLeft = useInstructorSurveyStore().surveyDaysLeft;

const showCountDown = daysLeft <= Config.INSTRUCTOR_SURVEY_DAYS_TO_SHOW_COUNTDOWN;
const countdownText = daysLeft > Config.INSTRUCTOR_SURVEY_LAST_CHANCE_THRESHOLD_IN_DAYS
                        ? t('INSTRUCTOR_SURVEY_DAYS_LEFT', daysLeft)
                        : t('INSTRUCTOR_SURVEY_DAYS_LAST_CHANCE');
</script>
