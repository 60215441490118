export default {
    en: {
        INSTRUCTOR_SURVEY_ALERT_LABEL: 'We value your feedback! Would you like to participate in the end-of-semester survey to help us improve your experience?',
        INSTRUCTOR_SURVEY_LINK_TEXT: 'Yes, take me to the survey',
        INSTRUCTOR_SURVEY_DIALOG_TITLE: "We'd love your feedback",
        INSTRUCTOR_SURVEY_DIALOG_TEXT: "As the semester comes to a close, we'd appreciate your feedback on your experience with the Range. Your insights will help us improve and make the platform even better for you.<br/><br/>Would you take a few minutes to complete our end-of-semester survey?",
        INSTRUCTOR_SURVEY_REMIND_ME_LATER: "Ask me again in a few days",
        INSTRUCTOR_SURVEY_NO_THANKS: "No, thanks!",
        INSTRUCTOR_SURVEY_DAYS_LEFT: "{count} Days Left!",
        INSTRUCTOR_SURVEY_DAYS_LAST_CHANCE: "Last Chance!",
        INSTRUCTOR_MINI_SURVEY_SUBMIT: "Submit",
        INSTRUCTOR_MINI_SURVEY_NPS_VERY_UNLIKELY: "Very Unlikely",
        INSTRUCTOR_MINI_SURVEY_NPS_VERY_LIKELY: "Very Likely",
        INSTRUCTOR_SURVEY_THANKS_DIALOG_TITLE: "Thank you!",
        INSTRUCTOR_SURVEY_THANKS_DIALOG_BODY: "Your response has been recorded, and we value your insights as we continually strive to improve our services.",
    }
}