<template>
    <cr-delete-confirmation-dialog id="removeAccessDialog" @confirm="onConfirmed" :disabled="isLoading" data-testing="remove-access-dialog">
        <template v-if="isLinkedAccess">
            <cr-html :value="t('SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_LINKED_CONFIRMATION', {accessEmail: catalogFamilyAccess?.email})"/>
            <br/>
            <br/>
            <p>{{ t('SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_LINKED_INFO') }}</p>
        </template>
        <template v-else-if="isOwnedAccess">
            <cr-html :value="t('SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_OWNED_CONFIRMATION', {accessEmail: catalogFamilyAccess?.email})"/>
            <br/>
            <br/>
            <p>{{ t('SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_OWNED_INFO', {organizationName}) }}</p>
        </template>
    </cr-delete-confirmation-dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useI18n } from 'vue-i18n';
import { CatalogFamilyAccessType, ICatalogFamilyAccess } from '@cyber-range/cyber-range-api-catalog-client';
import { useCatalogStore } from '../../../stores/catalogStore';
import { computed } from 'vue';
import { useOrganizationStore } from '../../../stores/organizationStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());

const props = defineProps<
{
    catalogFamilyAccess?: ICatalogFamilyAccess;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
}>();

const organizationName = useOrganizationStore().currentOrganizationName;

const isLinkedAccess = computed(() => props.catalogFamilyAccess?.accessType === CatalogFamilyAccessType.Linked);
const isOwnedAccess = computed(() => props.catalogFamilyAccess?.accessType === CatalogFamilyAccessType.Owned);

const onConfirmed = async ()=>
{
    await useCatalogStore().deleteCatalogFamilyAccess(props.catalogFamilyAccess!.id);
    emit('confirm');
}
</script>