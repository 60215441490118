export default {
    en: {
        // Saved Environment Page
        SAVED_ENVIRONMENT_PEOPLE_WITH_ACCESS: "People With Access",
        SAVED_ENVIRONMENT_VISIBLE_ACCESS: "Visible Access",
        SAVED_ENVIRONMENT_EDIT_SAVED_ENVIRONMENT: "Edit Saved Environment",
        SAVED_ENVIRONMENT_DEPLOY_SAVED_ENVIRONMENT: "Deploy Saved Environment",
        SAVED_ENVIRONMENT_DELETE_SAVED_ENVIRONMENT: "Delete Saved Environment",
        SAVED_ENVIRONMENT_SHARE_SAVED_ENVIRONMENT: "Share Saved Environment",
        SAVED_ENVIRONMENT_ADD_OWNER: "Add Owner",
        SAVED_ENVIRONMENT_DEFAULT_TITLE: "Saved Environment",
        DELETE_SAVED_ENVIRONMENT_CONFIRMATION: "Are you sure you want to delete <b>{familyName}</b>?",
        SHARE_SAVED_ENVIRONMENT_TITLE: "Share Saved Environment",
        SHARE_SAVED_ENVIRONMENT_DESCRIPTION: "To share this saved environment with others, enter their email addresses (separated by commas) and select the share type below.",
        SHARE_SAVED_ENVIRONMENT_KB_LINK_TEXT: "Learn more about sharing a saved environment",
        SHARE_SAVED_ENVIRONMENT_EMAILS_LABEL: "Email(s)",
        SHARE_SAVED_ENVIRONMENT_SHARE_TYPE_SELECT_LABEL: "Share Type",
        SHARE_SAVED_ENVIRONMENT_LINKED_LABEL: "Linked - Give read access to this saved environment.",
        SHARE_SAVED_ENVIRONMENT_COPIED_LABEL: "Copied - Give a copy of this saved environment.",
        SHARE_SAVED_ENVIRONMENT_SUCCESS_NOTIFICATION: "The environment was shared successfully.",

        // Saved Environment Info
        SAVED_ENVIRONMENT_INFO_SUMMARY: "Summary",
        SAVED_ENVIRONMENT_INFO_DESCRIPTION: "Description",
        SAVED_ENVIRONMENT_INFO_OS: "OS",
        SAVED_ENVIRONMENT_INFO_VERSION: "Version",
        SAVED_ENVIRONMENT_INFO_ORGANIZATION: "Organization",
        SAVED_ENVIRONMENT_INFO_CREATION: "Creation",
        SAVED_ENVIRONMENT_INFO_MODIFICATION: "Modification",
        SAVED_ENVIRONMENT_INFO_STATUS: "Status",

        // Saved Environment Access Table
        SAVED_ENVIRONMENT_ACCESS_TABLE_NAME: "Name",
        SAVED_ENVIRONMENT_ACCESS_TABLE_EMAIL: "Email",
        SAVED_ENVIRONMENT_ACCESS_TABLE_ACCESS_TYPE: "Access Type",
        SAVED_ENVIRONMENT_ACCESS_TABLE_VERSION: "Version",
        SAVED_ENVIRONMENT_ACCESS_TABLE_ACCESS_START_DATE: "Access Start Date",
        SAVED_ENVIRONMENT_ACCESS_TABLE_PENDING: "Pending",
        SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE: "Re-share",
        SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS: "Remove Access",
        SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_LINKED_CONFIRMATION: "Are you sure you want to remove access for <b>{accessEmail}</b> from this saved environment?",
        SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_LINKED_INFO: "The user will continue to be able to use existing exercise environments created from this saved environment but will not be able to create new exercise environments from it.",
        SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_OWNED_CONFIRMATION: "Are you sure you want to remove the owner's access to this saved environment?",
        SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS_OWNED_INFO: "Without an owner, this saved environment will be transferred to the organization, restricting access to course admins in {organizationName}.",
        SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_CONFIRMATION_DIALOG_TITLE: "Re-Share Saved Environment",
        SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_CONFIRMATION_DIALOG_MESSAGE: "Are you sure you want to re-share this saved environment with <b>{accessEmail}</b>?",
        SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_CONFIRMATION_DIALOG_EXPLANATION: "The user will receive an email to accept the sharing before they can access this saved environment.",
        SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_NOTIFICATION : "Access has been re-shared successfully.",
    }
};