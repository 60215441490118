<template>
    <cr-sidebar-header tag="h2"  class="cr-mt3" data-testing="sidebar-my-assets">
        {{ t('SIDEBAR_MY_ASSETS') }}
    </cr-sidebar-header>
    <cr-sidebar-item tag="span" :to="{name: Route.MySavedEnvironments.name}" data-testing="sidebar-my-saved-environments">
        <cr-icon class="sidebar-item-icon">bi-display</cr-icon> 
        <span class="sidebar-item-text">
            {{ t('SIDEBAR_MY_SAVED_ENVIRONMENTS') }}
        </span>
    </cr-sidebar-item>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import Route from '../../../routers/route';
const { t } = useI18n();
</script>