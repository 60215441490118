export default {
    en: {
        //Saved Environments Page
        SAVED_ENVIRONMENTS_PAGE_TITLE: "Saved Environments",

        // My Saved Environments Page
        MY_SAVED_ENVIRONMENTS_PAGE_TITLE: "My Saved Environments",

        // Saved Environments Table
        SAVED_ENVIRONMENTS_USER_FILTER_LABEL: "Owner",

        SAVED_ENVIRONMENTS_TABLE_HEADER_NAME: "Name",
        SAVED_ENVIRONMENTS_TABLE_HEADER_OPERATING_SYSTEMS: "OS",
        SAVED_ENVIRONMENTS_TABLE_HEADER_ORGANIZATION_NAME: "Organization",
        SAVED_ENVIRONMENTS_TABLE_HEADER_OWNER: "Owner",
        SAVED_ENVIRONMENTS_TABLE_OWNERSHIP_INDICATOR: "(You)",
        SAVED_ENVIRONMENTS_TABLE_HEADER_VERSION: "Version",

        SAVED_ENVIRONMENTS_TABLE_ACTION_VIEW: "View",
        SAVED_ENVIRONMENTS_TABLE_ACTION_DELETE: "Delete",
        SAVED_ENVIRONMENTS_TABLE_ACTION_REMOVE_ACCESS: "Remove Access",
        SAVED_ENVIRONMENTS_REMOVE_MY_ACCESS_CONFIRMATION: "Are you sure you want to remove your access to <b>{familyName}</b>?",
        SAVED_ENVIRONMENTS_REMOVE_MY_ACCESS_CONFIRM_TEXT: "Remove",
        SAVED_ENVIRONMENTS_REMOVE_MY_ACCESS_NOTIFICATION_TEXT: "Environment access was removed successfully.",
        SAVED_ENVIRONMENTS_DELETE_NOTIFICATION_TEXT: "The environment was deleted successfully.",

        //Bulk delete
        SAVED_ENVIRONMENTS_BULK_DELETE_BUTTON_LABEL: "Delete",
        SAVED_ENVIRONMENTS_BULK_DELETE_DELETE_DIALOG_MESSAGE: "Are you sure you want to delete <b>{itemCount} saved environment</b>?|Are you sure you want to delete <b>{itemCount} saved environments</b>?",
        SAVED_ENVIRONMENTS_BULK_DELETE_ARIA_LABEL: "Environment Deletion Progress",
        SAVED_ENVIRONMENTS_BULK_DELETE_JOB_DIALOG_TITLE: "Delete Environments",
    }
}
