import Config from "../../config";
import Route from '../route';
import { NavigationGuard } from "vue-router";
import { useAuthorizationStore } from "../../stores/authorizationStore";

const checkSavedEnvironmentAccess: NavigationGuard = async (to, from, next) =>
{
    const canCreateSnapshotInAtLeastOneContext = useAuthorizationStore().canCreateSnapshotInOrganization();
    if (
        canCreateSnapshotInAtLeastOneContext && 
        Config.FEATURE_SNAPSHOT_MANAGEMENT !== false // TODO REMOVE THIS ON FEATURE RELEASE
    )
    {
        return next();
    }
    else
    {
        return next(Route.Home);
    }

}
export default checkSavedEnvironmentAccess;
