import { ICatalogFamilyMetadata, CatalogMetadataTagKey } from "@cyber-range/cyber-range-api-catalog-client";

const TAG_OPERATING_SYSTEMS = 'operatingSystems';
const OPERATING_SYSTEM_UBUNTU = 'ubuntu';
const OPERATING_SYSTEM_KALI = 'kali';
const OPERATING_SYSTEM_WINDOWS = 'windows';
const OPERATING_SYSTEM_CENTOS = 'centos';
const OPERATING_SYSTEM_DEBIAN = 'debian';
const OPERATING_SYSTEMS = [
    OPERATING_SYSTEM_UBUNTU,
    OPERATING_SYSTEM_KALI,
    OPERATING_SYSTEM_WINDOWS,
    OPERATING_SYSTEM_CENTOS,
    OPERATING_SYSTEM_DEBIAN
]
const OPERATING_SYSTEM_WINDOWS_ICON = 'bi-windows'
const ENVIRONMENT_TYPE_CTF = 'ctf';
const ICON_CLOUD_CTF = 'cr-cloudctf';
const LABEL_CLOUD_CTF = 'cloud ctf';

export function useOperatingSystemIcons(metadata: ICatalogFamilyMetadata): { label: string, value: string }[]
{
    const results: { label: string, value: string }[] = [];

    for (const tag of [...new Set(metadata?.tags?.sort() || [])])
    {
        if (tag.startsWith(CatalogMetadataTagKey.EnvironmentTypes))
        {
            if (tag.endsWith(ENVIRONMENT_TYPE_CTF))
            {
                results.push({ label: LABEL_CLOUD_CTF, value: ICON_CLOUD_CTF })
            }
        }
        else if (tag.startsWith(TAG_OPERATING_SYSTEMS))
        {
            for (let operatingSystem of OPERATING_SYSTEMS)
            {
                if (tag.toLocaleLowerCase().includes(operatingSystem))
                {
                    if(operatingSystem === OPERATING_SYSTEM_WINDOWS)
                    {
                        results.push({ label: operatingSystem, value: OPERATING_SYSTEM_WINDOWS_ICON});
                    }
                    else
                    {
                        results.push({ label: operatingSystem, value: `fl-${operatingSystem === OPERATING_SYSTEM_KALI ? `${operatingSystem}-linux` : operatingSystem}` });
                    }
                    break;
                }
            }
        }
    }
    return results;
}