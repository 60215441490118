import { defineStore } from "pinia";
import Config from "../config";
import { useAuthorizationStore } from "./authorizationStore";
import { useAnalyticStore } from "./analyticStore";
import { useApiClientStore } from "./apiClientStore";
import { ISubmission, ISurvey } from "@cyber-range/cyber-range-api-survey-client";

function dismissKey(urlOrId: string): string
{
    return `instructorSurveyDismissed:${urlOrId}`;
}

function dateDiffInDays(startDate: Date, endDate: Date): number
{
    const dayInMs = 1000 * 60 * 60 * 24;
    return (endDate.getTime() - startDate.getTime())/dayInMs;
}

function surveyDismissedRecently(urlOrId: string): boolean
{
    const lastDismissedDate = localStorage.getItem(dismissKey(urlOrId));
    if (!lastDismissedDate)
    {
        return false;
    }
    const diff = dateDiffInDays(new Date(lastDismissedDate), new Date());

    return diff < Config.INSTRUCTOR_SURVEY_DISMISS_DURATION_IN_DAYS;
}

export const useInstructorSurveyStore = defineStore('instructorSurveyStore',
{
    state: () =>
    ({
        _hasTakenSurvey: undefined as (boolean|undefined),
        _hasTakenMiniSurvey: undefined as (boolean|undefined),
        /**
         * Temporarily hide instructor survey dialog while store data persists
         */
        showDialog: true,
        showMiniSurveyDialog: !surveyDismissedRecently(Config.INSTRUCTOR_SURVEY_ID),
    }),
    getters:
    {
        _isOngoingSurvey(): boolean
        {
            const hasStartDate = !!Config.INSTRUCTOR_SURVEY_START_DATE;
            const hasEndDate = !!Config.INSTRUCTOR_SURVEY_END_DATE

            const startDate = new Date(Config.INSTRUCTOR_SURVEY_START_DATE);
            const endDate = new Date(Config.INSTRUCTOR_SURVEY_END_DATE);
            const now = new Date();

            return (hasStartDate || hasEndDate) // has either a start or end date
                && (!hasStartDate || now > startDate) // if it has a start date: now is after the start date
                && (!hasEndDate || now < endDate); // if it has an end date: now is before the end date
        },
        surveyDismissedRecently(): boolean
        {
            return surveyDismissedRecently(Config.INSTRUCTOR_SURVEY_URL);
        },
        shouldShowSurvey(): boolean
        {
            return useAuthorizationStore().canViewInstructorSurvey() && !!Config.INSTRUCTOR_SURVEY_URL && this._isOngoingSurvey;
        },
        hasTakenSurvey(): boolean
        {
            return this.shouldShowSurvey && (this._hasTakenSurvey ??= localStorage.getItem("instructorSurvey") === Config.INSTRUCTOR_SURVEY_URL)
        },
        surveyDaysLeft(): number
        {
            const endDate = new Date(Config.INSTRUCTOR_SURVEY_END_DATE);
            const now = new Date();
            return Math.max(Math.ceil(dateDiffInDays(now, endDate)), 0);
        },
        url(): string
        {
            return Config.INSTRUCTOR_SURVEY_URL
        },
        shouldShowMiniSurvey(): boolean
        {
            return useAuthorizationStore().canViewInstructorSurvey()
                && !!Config.INSTRUCTOR_SURVEY_ID
                && this._isOngoingSurvey
        },
        hasTakenMiniSurvey(): boolean
        {
            return this.shouldShowMiniSurvey && (this._hasTakenMiniSurvey ??= localStorage.getItem("instructorSurveyId") === Config.INSTRUCTOR_SURVEY_ID)
        }
    },
    actions: {

        dismiss()
        {
            localStorage.setItem(dismissKey(Config.INSTRUCTOR_SURVEY_URL), new Date().toISOString().substring(0, 10));
            this.trackRemindMeLater();
        },
        dismissMiniSurvey()
        {
            localStorage.setItem(dismissKey(Config.INSTRUCTOR_SURVEY_ID), new Date().toISOString().substring(0, 10));
            this.trackRemindMeLater();
        },
        dismissPermanently()
        {
            localStorage.setItem("instructorSurvey", Config.INSTRUCTOR_SURVEY_URL);
            this._hasTakenSurvey = true;
            this.trackGoToSurvey();
        },
        dismissMiniSurveyPermanently()
        {
            localStorage.setItem("instructorSurveyId", Config.INSTRUCTOR_SURVEY_ID);
            this._hasTakenMiniSurvey = true;
            this.trackGoToSurvey();
        },

        trackDialogShow()
        {
            useAnalyticStore().trackEvent('Instructor Survey', 'Dialog shown');
        },

        trackRemindMeLater()
        {
            useAnalyticStore().trackEvent('Instructor Survey', 'Remind me later clicked');
        },

        trackGoToSurvey()
        {
            useAnalyticStore().trackEvent('Instructor Survey', 'Go to survey clicked');
        },
        async getSurvey(): Promise<ISurvey|undefined>
        {
            return Config.INSTRUCTOR_SURVEY_ID ? await useApiClientStore().surveyApiClient.getOne(Config.INSTRUCTOR_SURVEY_ID) : undefined;
        },
        async submitResponse(data: Pick<ISubmission, 'responses'>): Promise<void>
        {
            await useApiClientStore().surveyApiClient.submitSurveyResponse(Config.INSTRUCTOR_SURVEY_ID, data);
            this.dismissMiniSurveyPermanently();
        },
    }
})
