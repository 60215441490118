<template>
    <cr-delete-confirmation-dialog v-if="!jobId" v-model="showDeleteDialog" @confirm="onDeleteConfirmed" :disabled="isLoading" data-testing="bulk-delete-saved-environments-delete-confirmation">
        <cr-html :value="t('SAVED_ENVIRONMENTS_BULK_DELETE_DELETE_DIALOG_MESSAGE', {itemCount: environmentIds?.length || 0}, {plural: environmentIds?.length || 0})" />
    </cr-delete-confirmation-dialog>
    <job-progress-dialog v-model="showJobDialog" :job-id="jobId" :title="t('SAVED_ENVIRONMENTS_BULK_DELETE_JOB_DIALOG_TITLE')" @confirm="onConfirm" :label="t('SAVED_ENVIRONMENTS_BULK_DELETE_ARIA_LABEL')" data-testing="bulk-delete-saved-environments-job-progress"/>
</template>

<script lang="ts" setup>
import JobProgressDialog from '../../jobs/dialogs/JobProgressDialog.vue';
import { ICatalogFamily } from "@cyber-range/cyber-range-api-catalog-client";
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useApiClientStore } from '../../../stores/apiClientStore';
import { useAuthorizationStore } from '../../../stores/authorizationStore';
import { useCatalogStore } from '../../../stores/catalogStore';
import { useI18n } from 'vue-i18n';
import { useJobStore } from '../../../stores/jobStore';

const { t } = useI18n();
const { isLoading } = storeToRefs(useApiClientStore());
const { canDeleteCatalogFamily } = useAuthorizationStore();
const { deleteCatalogFamily, removeMyAccess } = useCatalogStore();
 
const props = defineProps<
{
    currentUserId:string|undefined;
    mine: boolean;
    myEnvironmentsMap: Map<string,ICatalogFamily>;
    environmentIds: string[];
    modelValue?: boolean;
}>();

const emit = defineEmits<{
    (name: 'confirm'): void
    (name: 'update:modelValue', value: boolean): void
}>();

const showDeleteDialog = computed(
    {
        get: () => props.modelValue,
        set: (value: boolean) => emit('update:modelValue', value)
    }
)

const showJobDialog = ref<boolean>(false)

const jobId = ref<string>("");

// returns either CatalogFamilyApiClient.delete or CatalogFamilyAccessApiClient.removeMyAccess based on context and claims.
const getBulkRemovalMethod = (catalogFamilyId:string):(catalogFamilyId:string,options:{bulk?:boolean})=> Promise<void> =>
{    
    const catalogFamily = props.myEnvironmentsMap.get(catalogFamilyId);
    
    if(!catalogFamily) // undefined implies neither owned nor link shared, must be deletable
    {
        return deleteCatalogFamily 
    }

    if(catalogFamily?.userId === props.currentUserId) // on both environments pages, an owner can delete their own item
    {
        return deleteCatalogFamily
    }    
    else if(!!props.mine) // link shared on my environments page
    {
        return removeMyAccess        
    }

    return canDeleteCatalogFamily(catalogFamily, props.currentUserId) // delete if permitted to
        ? deleteCatalogFamily
        : removeMyAccess
}

const onDeleteConfirmed = async () =>
{
    showJobDialog.value = true;
    for(let environmentId of props.environmentIds)
    {
        await ((getBulkRemovalMethod(environmentId))(environmentId,{ bulk: true}));
    }
    jobId.value = await useJobStore().submitJob();
}

const onConfirm = async () => 
{
    jobId.value = "";
    emit("confirm");
}
</script>