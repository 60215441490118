<template>
    <template v-if="canManageOrganization() || canApproveCourses || canApproveInvitations || canViewDevTools() || canViewLogs">
        <cr-sidebar-header tag="h2"  class="cr-mt3" data-testing="sidebar-admin">
            {{ t('SIDEBAR_ADMIN') }}
        </cr-sidebar-header>
        <cr-sidebar-item v-if="canManageOrganization()"  tag="span" :to="{name: Route.Organizations.name}" data-testing="sidebar-admin-organizations">
            <cr-icon class="sidebar-item-icon">bi-diagram-3</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_ADMIN_ORGANIZATIONS') }}
            </span>
        </cr-sidebar-item>
        <cr-sidebar-item v-if="canApproveCourses"  tag="span" :to="{name: Route.Courses.name}" data-testing="sidebar-admin-courses">
            <cr-icon class="sidebar-item-icon">bi-journals</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_COURSES') }}
            </span>
        </cr-sidebar-item>
        <course-approvals-sidebar-item v-if="canApproveCourses" data-testing="sidebar-admin-course-approvals" />
        <cr-sidebar-item v-if="canViewOrganizationCatalogFamilies() && !!Config.FEATURE_SNAPSHOT_MANAGEMENT" tag="span" :to="{name: Route.SavedEnvironments.name}" data-testing="sidebar-admin-saved-environments">
            <cr-icon class="sidebar-item-icon">bi-display</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_SAVED_ENVIRONMENTS') }}
            </span>
        </cr-sidebar-item>
        <cr-sidebar-item v-if="canManageOrganization()"  tag="span" :to="{name: Route.Users.name}" data-testing="sidebar-admin-users">
            <cr-icon class="sidebar-item-icon">bi-people-fill</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_USERS') }}
            </span>
        </cr-sidebar-item>
        <cr-sidebar-item v-if="canViewDevTools()" tag="span" :to="{name: Route.SuperAdmins.name}" data-testing="sidebar-admin-super-admins">
            <cr-icon class="sidebar-item-icon">bi-person-arms-up</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_SUPER_ADMINS') }}
            </span>
        </cr-sidebar-item>
        <account-approvals-sidebar-item v-if="canApproveInvitations" data-testing="sidebar-admin-account-approvals"/>
        <cr-sidebar-item v-if="canViewDevTools()" tag="span" :to="{name: Route.Plans.name}" data-testing="sidebar-admin-plans">
            <cr-icon class="sidebar-item-icon">bi-box-seam</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_PLANS') }}
            </span>
        </cr-sidebar-item>
        <cr-sidebar-item v-if="canViewLogs" tag="span" :to="{name: Route.Logs.name}" data-testing="sidebar-admin-view-logs">
            <cr-icon class="sidebar-item-icon">bi-journal</cr-icon> 
            <span class="sidebar-item-text">
                {{ t('SIDEBAR_VIEW_LOGS') }}
            </span>
        </cr-sidebar-item>
    </template>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Route from '../../../routers/route';
import { useAuthorizationStore } from '../../../stores/authorizationStore';
import { useUserStore } from '../../../stores/userStore';
import AccountApprovalsSidebarItem from './admin/AccountApprovalsSidebarItem.vue';
import CourseApprovalsSidebarItem from './admin/CourseApprovalsSidebarItem.vue';
import Config from '../../../config';
const { t } = useI18n(); 

const { canManageOrganization, canViewDevTools, canViewOrganizationCatalogFamilies } = useAuthorizationStore()
const canApproveInvitations = computed(() => useAuthorizationStore().canApproveInvitations() )
const canApproveCourses = computed(() => useAuthorizationStore().canApproveCourses());
const userStore = useUserStore();

const canViewLogs = computed(() => useAuthorizationStore().canViewAuditEvents(userStore.currentUser?.organizations.map(o => o.organizationId)));
onMounted(async () => 
{
    // We only need to fetch user for claims check with organization ids if they don't already have permission to view all
    if (!canViewLogs.value)
    {
        await userStore.fetchCurrentUser();
    }
});
</script>