<template>
    <cr-textarea label="" label-above v-model="answer" :rows="5" :required="question.required">
        <template #label>
            <cr-html :value="question.text" mode="discard" />
        </template>
    </cr-textarea>
</template>

<script setup lang="ts">
import { IQuestion } from '@cyber-range/cyber-range-api-survey-client';
import { ISubmissionResponseAnswer } from '@cyber-range/cyber-range-api-survey-client/dist/interfaces/iSubmissionResponseAnswer';
import { computed } from 'vue';

const props = defineProps<{ question: IQuestion }>();
const answerModelValue = defineModel<ISubmissionResponseAnswer>('answer');
const answer = computed({
    get: () => answerModelValue.value?.value || '',
    set: (value: string) => { answerModelValue.value = { id: props.question.answers[0].id, value } }
});
</script>
