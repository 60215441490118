<template>
    <instructor-mini-survey-question-nps v-if="question.type == QuestionType.NPS" :question="question" v-model:answer="modelValue" />
    <instructor-mini-survey-question-textarea v-else-if="question.type == QuestionType.Textarea" :question="question" v-model:answer="modelValue" />
</template>

<script setup lang="ts">
import { IQuestion, QuestionType } from '@cyber-range/cyber-range-api-survey-client';
import { ISubmissionResponseAnswer } from '@cyber-range/cyber-range-api-survey-client/dist/interfaces/iSubmissionResponseAnswer';
import InstructorMiniSurveyQuestionNps from './InstructorMiniSurveyQuestionNPS.vue';
import InstructorMiniSurveyQuestionTextarea from './InstructorMiniSurveyQuestionTextarea.vue';

defineProps<{ question: IQuestion }>();
const modelValue = defineModel<ISubmissionResponseAnswer>('answer');
</script>
