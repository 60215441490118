import { CatalogFamilyAccessType, CatalogFamilyFilter, CatalogFamilySearchFilter, ICatalogEntry, ICatalogFamily, ICatalogFamilyAccess, ICatalogFamilyAccessApiClient, ICatalogFamilyAccessFilter, ICatalogFamilyFilter, ICatalogFamilySearchFilter } from '@cyber-range/cyber-range-api-catalog-client';
import { defineStore } from 'pinia';
import { useApiClientStore } from './apiClientStore';
import { IApiPageResponse } from '@cyber-range/cyber-range-api-client';
import { ICatalogFamilyApiClient } from '@cyber-range/cyber-range-api-catalog-client/dist/interfaces/iCatalogFamilyApiClient';

export const useCatalogStore = defineStore('catalogStore', 
{
    state: () => ({
        catalogsById: new Map<string,ICatalogEntry>()
    }),

    actions: 
    {
        async fetch(catalogId: string, options?: {force?: boolean, background?:boolean}): Promise<ICatalogEntry|undefined>
        {
            if (!this.catalogsById.has(catalogId) || options?.force)
            {
                let catalogApiClient = options?.background ? useApiClientStore().backgroundCatalogApiClient : useApiClientStore().catalogApiClient;
                const catalogEntry = await catalogApiClient.getOne(catalogId);
                this.catalogsById.set(catalogId, catalogEntry);
            }
            return this.catalogsById.get(catalogId);
        },

        async searchCatalogFamilies(searchFilter?: Partial<ICatalogFamilySearchFilter>): Promise<ICatalogFamily[]>
        {
            const apiClientStore = useApiClientStore();
            return (searchFilter)
                ? await apiClientStore.catalogFamilySearchApiClient.searchAll(searchFilter)
                : await apiClientStore.catalogFamilyApiClient.getAll(new CatalogFamilyFilter({ organizationId: CatalogFamilySearchFilter.GLOBAL_ENTRY_ORGANIZATION_ID}));
        },

        async listCatalogFamilies(filter?: Partial<ICatalogFamilyFilter>): Promise<IApiPageResponse<ICatalogFamily>>
        {
            const apiClientStore = useApiClientStore();
            return await apiClientStore.catalogFamilyApiClient.get(filter);
        },

        async listAllCatalogFamilies(filter?: Partial<ICatalogFamilyFilter>): Promise<ICatalogFamily[]>
        {
            const apiClientStore = useApiClientStore();
            return await apiClientStore.catalogFamilyApiClient.getAll(filter);
        },

        async getCatalogFamily(catalogFamilyId: string): Promise<ICatalogFamily|undefined>
        {
            const apiClientStore = useApiClientStore();
            return apiClientStore.catalogFamilyApiClient.getOne(catalogFamilyId);
        },

        async deleteCatalogFamily(catalogFamilyId: string, options?: { background?: boolean, bulk?:boolean}): Promise<void>
        {
            let catalogFamilyApiClient:ICatalogFamilyApiClient = options?.background ? useApiClientStore().backgroundCatalogFamilyApiClient : useApiClientStore().catalogFamilyApiClient;
            catalogFamilyApiClient = options?.bulk ? useApiClientStore().bulkCatalogFamilyApiClient : catalogFamilyApiClient;
            await catalogFamilyApiClient.delete(catalogFamilyId);
        },

        async removeMyAccess(catalogFamilyId: string, options?: { background?: boolean, bulk?:boolean}): Promise<void>
        {
            let catalogFamilyAccessApiClient:ICatalogFamilyAccessApiClient = options?.background ? useApiClientStore().backgroundCatalogFamilyAccessApiClient : useApiClientStore().catalogFamilyAccessApiClient;
            catalogFamilyAccessApiClient = options?.bulk ? useApiClientStore().bulkCatalogFamilyAccessApiClient : catalogFamilyAccessApiClient;
            await catalogFamilyAccessApiClient.removeMyAccess(catalogFamilyId);
        },

        async listCatalogFamilyAccesses(familyId: string, filter?: Partial<ICatalogFamilyAccessFilter>, options?: {background?:boolean}): Promise<IApiPageResponse<ICatalogFamilyAccess>>
        {
            let catalogFamilyAccessApiClient = options?.background ? useApiClientStore().backgroundCatalogFamilyAccessApiClient : useApiClientStore().catalogFamilyAccessApiClient;
            return await catalogFamilyAccessApiClient.getCatalogFamilyAccesses(familyId, filter);
        },

        async deleteCatalogFamilyAccess(catalogFamilyAccessId: string, options?: { background?: boolean}): Promise<void>
        {
            let catalogFamilyAccessApiClient = options?.background ? useApiClientStore().backgroundCatalogFamilyAccessApiClient : useApiClientStore().catalogFamilyAccessApiClient;
            await catalogFamilyAccessApiClient.delete(catalogFamilyAccessId);
        },
        
        async createCatalogFamilyAccesses(catalogFamilyId: string, accessType: CatalogFamilyAccessType, recipients: string[], options?: { background?: boolean}): Promise<void>
        {
            let catalogFamilyAccessApiClient = options?.background ? useApiClientStore().backgroundCatalogFamilyAccessApiClient : useApiClientStore().catalogFamilyAccessApiClient;
            await catalogFamilyAccessApiClient.create(catalogFamilyId, accessType, recipients);
        },
        
        async reShareCatalogFamilyAccess(catalogFamilyAccessId: string, options?: { background?: boolean }): Promise<void>
        {
            let catalogFamilyAccessApiClient = options?.background ? useApiClientStore().backgroundCatalogFamilyAccessApiClient : useApiClientStore().catalogFamilyAccessApiClient;
            await catalogFamilyAccessApiClient.reShare(catalogFamilyAccessId);
        },
    }
});
