import { IClaim } from '@cyber-range/cyber-range-api-entitlement-client';
import * as Claims from '@cyber-range/cyber-range-shared-claims';
import { defineStore } from 'pinia';
import { useEntitlementStore } from "./entitlementStore";
import Config from '../config';
import { VendibleType } from '@cyber-range/cyber-range-api-payment-client';
import { ICatalogFamily } from '@cyber-range/cyber-range-api-catalog-client';

function andOnlyIfDefined(v:string|string[]|undefined): string[]
{
    return v ? (Array.isArray(v) ? v : [v]) : [];
}

function hasClaim(type: string, valueOrValues: string | string[], scopeOrScopes?: string | string[]): boolean
{
    let scopes = scopeOrScopes ? (Array.isArray(scopeOrScopes) ? scopeOrScopes : [scopeOrScopes]) : useEntitlementStore().scopes;
    
    return useEntitlementStore().hasClaim(type, valueOrValues, [...scopes, Claims.Scope.UNDEFINED, Claims.Scope.ANY]);
}

export const useAuthorizationStore = defineStore('authorizationStore', 
{
    actions: 
    {
        /****************************
         * Misc
         ****************************/
        canViewDevTools(): boolean
        {
            return hasClaim(Claims.Type.CAN_VIEW_DEV_TOOLS, Claims.Value.ANY);
        },
        canNavigateOutsideCourse(): boolean
        {
            return !hasClaim(Claims.Type.SANDBOX, Claims.Value.COURSE);
        },
        canViewNewUI(): boolean
        {
            return Config.FEATURE_CAN_VIEW_NEW_UI_CLAIM_CHECK
                ? hasClaim(Claims.Type.CAN_VIEW_NEW_UI, Claims.Value.ANY) || !hasClaim(Claims.Type.CAN_NOT_VIEW_NEW_UI, Claims.Value.ANY)
                : true;
        },
        canViewInstructorSurvey(): boolean
        {
            return hasClaim(Claims.Type.CAN_VIEW_INSTRUCTOR_SURVEY, Claims.Value.ANY);
        },
        /****************************
         * Course
         ****************************/
        canAccessCourse(courseId: string)
        {
            return hasClaim(Claims.Type.COURSE, [courseId, Claims.Value.ANY])
        },
        canCreateCourse(organizationIds?:string[]): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_COURSE, organizationIds);
        },
        canListCourses(resourceIds?:string[]): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_COURSES, resourceIds);
        },
        canSetAnyCourseExpirationDate(organizationId?:string): boolean
        {
            return hasClaim(Claims.Type.CAN_UPDATE_COURSE_AVAILABILITY, Claims.Value.ANY, organizationId);
        },
        canUpdateCourseAvailability(courseId: string, organizationId?: string): boolean
        {
            return hasClaim(Claims.Type.CAN_UPDATE_COURSE_AVAILABILITY, [Claims.Value.ANY, '1'], [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canSkipCourseRequest(organizationId?: string): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_COURSE_APPROVE, organizationId);
        },
        canApproveCourses(organizationId?: string): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_COURSE_APPROVE, organizationId);
        },
        canViewCourseTimestamp(courseId:string, organizationId:string): boolean
        {
            return hasClaim(Claims.Type.CAN_VIEW_COURSE_TIMESTAMP, [courseId, organizationId, Claims.Value.ANY]);
        },
        canListCourseUsers(courseId:string, organizationId?:string): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_COURSE_USERS, [ ...andOnlyIfDefined(courseId), ...andOnlyIfDefined(organizationId)]);
        },
        canEditCourse(courseId:string, organizationId?:string): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_COURSE, [ ...andOnlyIfDefined(courseId), ...andOnlyIfDefined(organizationId)]);
        },
        canDeleteCourse(courseId:string, organizationId?:string): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_COURSE, [ courseId, ...andOnlyIfDefined(organizationId) ])
        },
        canViewAllTeamsInCourse(courseId:string, organizationId?:string): boolean
        {
            return hasClaim(Claims.Type.CAN_VIEW_ALL_TEAMS_IN_COURSE, [ Claims.Value.ANY, courseId ], [ courseId, ...andOnlyIfDefined(organizationId) ])
        },
        canInviteUsers(courseId:string, organizationId?:string): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_INVITATION, [ courseId, ...andOnlyIfDefined(organizationId) ])
        },
        canCreateInvitationCode(courseId:string, organizationId?:string): boolean
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_COURSE_CODE, [ courseId, ...andOnlyIfDefined(organizationId) ])
        },
        getRoles(courseId:string, organizationId:string): string[]
        {
            let roleClaims: IClaim[] = useEntitlementStore().getClaims("role", undefined, [courseId, organizationId, Claims.Scope.UNDEFINED, Claims.Scope.ANY]);
    
            return roleClaims
                .filter(
                    claim =>
                        claim.value !== "user" &&
                        (claim.scopes.includes(courseId) ||
                            claim.scopes.includes(organizationId) ||
                            claim.scopes.includes(Claims.Scope.ANY))
                )
                .map(claim => claim.value);
        },
        canViewOrganizationContact(organizationId?:string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_ORGANIZATION_CONTACT, [Claims.Value.ANY, ...andOnlyIfDefined(organizationId)]);
        },
        canManageOrganization(organizationId?:string)
        {
            return hasClaim(Claims.Type.CAN_MANAGE_ORGANIZATION, [Claims.Value.ANY, ...(organizationId? [organizationId] :useEntitlementStore().scopes)]);
        },
        canListCourseApplications(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_COURSE_APPLICATIONS, [ courseId, organizationId ]);
        },
        canDeleteCourseApplication(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_COURSE_APPLICATION, [ courseId, organizationId ]);

        },
        isCourseRequestorOnly(courseId: string)
        {
            return hasClaim(Claims.Type.ROLE, Claims.Value.COURSE_REQUESTER, courseId)
                && !hasClaim(Claims.Type.ROLE, Claims.Value.INSTRUCTOR, courseId);
        },
        /****************************
         * BusinessUnits
         ****************************/
        canManageBusinessUnits()
        {
            return hasClaim(Claims.Type.ORGANIZATION, Claims.Value.ANY, Claims.Value.ANY);
        },
        canCreateBusinessUnit(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_BUSINESS_UNIT, [organizationId]);
        },
        canEditBusinessUnit()
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_BUSINESS_UNIT, Claims.Value.ANY);
        },

        /****************************
         * Exercise
         ****************************/
        canStartExercise(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXERCISE_START, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canStopExercise(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXERCISE_STOP, [courseId, organizationId]);
        },
        canRestartExercise(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXERCISE_RESTART, [courseId, organizationId]);
        },
        canResetExercise(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXERCISE_RESET, [courseId, organizationId]);
        },
        canSnapshotExercise(courseId: string, organizationId: string, exerciseId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXERCISE_SNAPSHOT, [organizationId])
                || (hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXERCISE_SNAPSHOT, [courseId])
                    && hasClaim(Claims.Type.EXERCISE, exerciseId, Claims.Scope.ANY));
        },
        canJoinExercise(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_EXERCISE_JOIN, [courseId, organizationId]);
        },
        canViewDisabledExercises(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_ALL_TEAMS_IN_COURSE, [courseId, Claims.Value.ANY], [courseId,  ...andOnlyIfDefined(organizationId)]);
        },
        canViewSystemInfo(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_SYSTEM_INFO, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canListExerciseGroupExercises(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_EXERCISE_GROUP_EXERCISES, [courseId, organizationId]);
        },

        
        /****************************
         * Team
         ****************************/
        canCreateCourseTeam(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_COURSE_TEAM, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canUpdateCourseTeam(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PACTH_V1_COURSE_TEAM, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canDeleteCourseTeam(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_COURSE_TEAM, [courseId, ...andOnlyIfDefined(organizationId)]);
        },


        /****************************
         * Invitation
         ****************************/
        canInvite(role:string, courseId: string, organizationId?: string)
        {
            return hasClaim(`can_invite_${role}`, [courseId, ...andOnlyIfDefined(organizationId), Claims.Value.ANY], [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canResendCourseInvitation(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_INVITATION_RESEND, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canUpdateInvitation(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_INVITATION, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canDeleteCourseInvitation(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_INVITATION, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canDeleteOrganizationInvitation(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_INVITATION, [organizationId]);
        },
        canInviteOrganizationAdmin(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_ORGANIZATIONADMIN, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canInviteBillingAdmin(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_BILLINGADMIN, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canInviteCourseAdmin(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_COURSEADMIN, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canInviteCoursewareAdmin(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_COURSEWAREADMIN, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canInviteOrganizationInstructor(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_INSTRUCTOR, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canInviteOrganizationStudent(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_STUDENT, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canInviteOrganizationTa(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_TA, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canInviteSuperAdmin()
        {
            return hasClaim(Claims.Type.CAN_INVITE_SUPERADMIN, Claims.Value.ANY);

        },


        /****************************
         * Users
         ****************************/
        canUpdateUserCourseRole(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_USER_COURSE_ROLE, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canUpdateUser(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_USER, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canDeleteCourseUser(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_COURSE_USER, [courseId,...andOnlyIfDefined(organizationId)]);
        },
        canInviteOrganizationUsers(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_INVITATION, [organizationId]);
        },
        canImpersonate(courseId?: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_IMPERSONATE, [...andOnlyIfDefined(courseId), ...andOnlyIfDefined(organizationId), Claims.Value.ANY], [...andOnlyIfDefined(courseId), ...andOnlyIfDefined(organizationId)]);
        },
        canApproveInvitations(organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_INVITATION_APPROVE, organizationId);
        },

        /****************************
         * Organization
         ****************************/
        canCreateOrganization(organizationIds?: string[])
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_ORGANIZATION, [...andOnlyIfDefined(organizationIds)]);
        },
        canEditOrganization(organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_ORGANIZATION, [...andOnlyIfDefined(organizationId)]);
        },
        canEditOrganizationAlternateId(organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_ORGANIZATION_ALTERNATE_ID, [...andOnlyIfDefined(organizationId)]);
        },
        canEditOrganizationCode(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_UPDATE_ORGANIZATION_CODE, [organizationId,Claims.Value.ANY], [organizationId])
        },
        canEditOrganizationLogo(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_UPDATE_ORGANIZATION_LOGO, [organizationId,Claims.Value.ANY], [organizationId])
        },
        canEditOrganizationName(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_UPDATE_ORGANIZATION_NAME, [organizationId,Claims.Value.ANY], [organizationId])
        },
        canSetOrganizationParent(organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_ORGANIZATION_PARENTID, [...andOnlyIfDefined(organizationId)]);
        },
        canViewOrganizationUsers(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_ORGANIZATION_USERS, [organizationId,Claims.Value.ANY], [organizationId]);
        },
        canListOrganizationApplications(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_ORGANIZATION_APPLICATIONS, [organizationId]);
        },
        canDeleteOrganization(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_ORGANIZATION, [organizationId]);
        },
        canDeleteOrganizationUsers(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_USER_ORGANIZATION_REMOVE, [organizationId]);
        },
        canUpdateOrganizationUser(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_USER, [organizationId]);
        },
        canUpdateOrganizationUserRoles(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_USER_ORGANIZATION_ROLE, [organizationId]);
        },
        canListOrganizationApplication(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_ORGANIZATION_APPLICATIONS, [organizationId])
        },
        canCreateOrganizationApplication(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_ORGANIZATION_APPLICATION, [organizationId])
        },
        canUpdateOrganizationApplication(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_ORGANIZATION_APPLICATION, [organizationId])
        },
        canDeleteOrganizationApplication(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_ORGANIZATION_APPLICATION, [organizationId])
        },
        canUpdateOrganizationLimits(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_ORGANIZATION_LIMITS, [organizationId]);
        },
        canUpdateAllOrganizationLimits(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_UPDATE_ORGANIZATION_ALL_LIMITS, Claims.Value.ANY, [organizationId]);
        },
        canManageOrganizationCustomAttributes(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_UPDATE_ORGANIZATION_INTERNAL_CUSTOM_ATTRIBUTES, Claims.Value.ANY, [organizationId]);
        },


        /****************************
         * Billing
         ****************************/
        canViewBilling(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_BILLING, [organizationId, Claims.Value.ANY],[organizationId]);
        },
        canUpdateBilling(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_UPDATE_BILLING, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canRenewSubscription(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_SUBSCRIPTION_RENEW, [organizationId]);
        },
        canViewUsages(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_USAGES, [organizationId]);
        },
        canCreateBalanceActivity(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_ORGANIZATION_BALANCE_ACTIVITY, [organizationId]);
        },
        canViewBillingAdmins(organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_INVITE_BILLINGADMIN, [organizationId, Claims.Value.ANY], [organizationId]);
        },
        canCreateUpFrontPaymentLink(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_VENDIBLE, [organizationId]);
        },
        canMakeOrganizationPayment(organization: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_ORGANIZATION_VENDIBLE, [organization]);
        },
        canViewPayments(type: VendibleType, resourceId: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_PAYMENTS, [Claims.Value.ANY, `${resourceId}_${type}`], [resourceId])
        },
        canCreateProduct()
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_PRODUCT);
        },
        canListProducts()
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_PRODUCTS);
        },
        canViewProduct()
        {
            return hasClaim(Claims.Type.API, Claims.Value.GET_V1_PRODUCT);
        },
        canUpdateProduct()
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_PRODUCT);
        },
        canDeleteProduct()
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_PRODUCT);
        },

        /****************************
         * Exercise Groups
         ****************************/
        canCreateExerciseGroup(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_COURSE_EXERCISE_GROUP, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canEditExerciseGroup(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_COURSE_EXERCISE_GROUP, [courseId, organizationId]);
        },
        canDeleteExerciseGroup(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_COURSE_EXERCISE_GROUP, [courseId, organizationId]);
        },
        canViewCourseResources(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_COURSE_RESOURCES, [Claims.Value.ANY, courseId, organizationId]);
        },
        canUpdateCourseExerciseGroupFeature(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_COURSE_EXERCISE_GROUP_FEATURE, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canRemoveNotesFromExerciseGroups(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_EXERCISE_GROUP_NOTE, [courseId, ...andOnlyIfDefined(organizationId)]);
        },

        /****************************
         * Files
         ****************************/
        canViewFiles()
        {
            return hasClaim(Claims.Type.API, Claims.Value.GET_V1_FILE);
        },

        /****************************
         * Credential
         ****************************/
        canResetPassword(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_CREDENTIAL_RESET, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canDeleteCredential(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_CREDENTIAL, [courseId, ...andOnlyIfDefined(organizationId)]);
        },
        canCreateCredentials(courseId: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_CREDENTIAL, [courseId, ...andOnlyIfDefined(organizationId)]);
        },

        /****************************
         * CTF
         ****************************/
        canAccessCompetition(competitionId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.CTF_COMPETITION, [competitionId, Claims.Value.ANY], competitionId)
                || hasClaim(Claims.Type.CAN_VIEW_ALL_COMPETITIONS, [organizationId, Claims.Value.ANY], organizationId);
        },
        canSnapshotCompetition(courseId: string, organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_COMPETITION_SNAPSHOT, [courseId, organizationId])
        },
        canCreateEnvironmentArtifact(competitionId?: string, organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_CREATE_ENVIRONMENT_ARTIFACT, [Claims.Value.ANY, ...andOnlyIfDefined(competitionId), ...andOnlyIfDefined(organizationId)], [...andOnlyIfDefined(competitionId), ...andOnlyIfDefined(organizationId)])
        },

        /****************************
         * Support
         ****************************/
        canRequestOrganizationSupport(organizationId: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.POST_V1_ORGANIZATION_SUPPORT,[organizationId]);
        },

        /****************************
         * Audit
         ****************************/
        canViewAnyAuditEvent()
        {
            return hasClaim(Claims.Type.CAN_VIEW_AUDIT_EVENT, Claims.Value.ANY);
        },
        canViewAuditEvents(organizationIds?: string|string[], courseIds?: string|string[])
        {
            if (organizationIds)
            {
                organizationIds = Array.isArray(organizationIds) ? organizationIds : [organizationIds];
                organizationIds = organizationIds.flatMap(id => [id, `${id}_course`]);
            }
            return hasClaim(Claims.Type.CAN_VIEW_AUDIT_EVENT, [
                Claims.Value.ANY,
                ...andOnlyIfDefined(organizationIds),
                ...andOnlyIfDefined(courseIds)
            ]);
        },

        /****************************
         * Catalog
         ****************************/
        canCreateCatalogFamilyAccesses(organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_CREATE_CATALOG_FAMILY_ACCESS, [Claims.Scope.ANY, ...andOnlyIfDefined(organizationId)], [...andOnlyIfDefined(organizationId)]);
        },
        canViewCatalogFamilyAccesses(organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_CATALOG_FAMILY_ACCESSES, [Claims.Scope.ANY, ...andOnlyIfDefined(organizationId)], [...andOnlyIfDefined(organizationId)]);
        },
        canDeleteCatalogFamilyAccesses(organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_DELETE_CATALOG_FAMILY_ACCESS, [Claims.Scope.ANY, ...andOnlyIfDefined(organizationId)], [...andOnlyIfDefined(organizationId)]);
        },
        canUpdateAnyCatalogFamily()
        {
            return hasClaim(Claims.Type.API, Claims.Value.PATCH_V1_CATALOG_FAMILY, Claims.Scope.ANY)
        },
        canDeleteAnyCatalogFamily()
        {
            return hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_CATALOG_FAMILY, Claims.Scope.ANY)
        },
        canDeleteCatalogFamily(family:ICatalogFamily, currentUserId:string|undefined)
        {
            const hasRouteClaim = hasClaim(Claims.Type.API, Claims.Value.DELETE_V1_CATALOG_FAMILY, family.organizationId );
            const isOwner = (family.userId === currentUserId);
            const hasDeleteAnyFamilyFromOrganizationClaim = hasClaim(Claims.Type.CAN_DELETE_ORGANIZATION_CATALOG_FAMILY, [ Claims.Value.ANY, family.organizationId],family.organizationId);

            return (hasRouteClaim && (isOwner || hasDeleteAnyFamilyFromOrganizationClaim));
        },
        canAddCatalogFamilyOwner(organizationId?: string)
        {
            return hasClaim(Claims.Type.API, Claims.Value.PUT_V1_CATALOG_FAMILY_OWNER, [...andOnlyIfDefined(organizationId)])
        },
        canListCatalogFamilies()
        {
            return hasClaim(Claims.Type.API, Claims.Value.LIST_V1_CATALOG_FAMILIES)
        },
        canViewOrganizationCatalogFamilies(organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_VIEW_CATALOG_FAMILIES, [Claims.Value.ANY, ...(organizationId? [organizationId] :useEntitlementStore().scopes)]);
        },
        canCreateSnapshotInOrganization(organizationId?: string)
        {
            return hasClaim(Claims.Type.CAN_CREATE_SNAPSHOT_IN_ORGANIZATION, [Claims.Value.ANY, ...(organizationId? [organizationId] :useEntitlementStore().scopes)]);
        }
    }
});
