<template>
    <cr-confirmation-dialog v-if="showSurvey" v-model="surveyStore.showDialog" :title="t('INSTRUCTOR_SURVEY_DIALOG_TITLE')" :confirm-text="t('INSTRUCTOR_SURVEY_LINK_TEXT')" :cancel-text="cancelText" @confirm="onConfirmClicked" @cancel="onCancelClicked">
        <instructor-survey-expiration-countdown class="cr-mb4"/>
        <p v-html="t('INSTRUCTOR_SURVEY_DIALOG_TEXT')" />
    </cr-confirmation-dialog>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useInstructorSurveyStore } from '../../stores/instructorSurveyStore';
import InstructorSurveyExpirationCountdown from './InstructorSurveyExpirationCountdown.vue';
import Config from '../../config';
const { t } = useI18n()

const surveyStore = useInstructorSurveyStore();
const showSurvey = computed(() => surveyStore.shouldShowSurvey && !surveyStore.hasTakenSurvey && !surveyStore.surveyDismissedRecently)
const cancelText = surveyStore.surveyDaysLeft <= Config.INSTRUCTOR_SURVEY_LAST_CHANCE_THRESHOLD_IN_DAYS
                        ? t('INSTRUCTOR_SURVEY_NO_THANKS')
                        : t('INSTRUCTOR_SURVEY_REMIND_ME_LATER')

function onConfirmClicked()
{
    surveyStore.dismissPermanently();
    window.open(surveyStore.url, '_blank');
}
function onCancelClicked()
{
    surveyStore.showDialog = false;
    surveyStore.dismiss();
}

watch(showSurvey, () =>
{
    if (showSurvey.value && surveyStore.showDialog)
    {
        surveyStore.trackDialogShow();
    }
}, { immediate: true });
</script>
