import { IBreadcrumbItem } from '@cyber-range/cyber-range-lib-ui';
import { Ref, watchEffect } from "vue";
import Route from "../routers/route";
import { useWhoseSavedEnvironment } from "./useWhoseSavedEnvironment";

export function useSavedEnvironmentBreadcrumbs(breadcrumbs: Ref<IBreadcrumbItem[]>): Ref<IBreadcrumbItem[]>
{
    let whoseSavedEnvironment = useWhoseSavedEnvironment();

    watchEffect(() =>
    {
        if(whoseSavedEnvironment.value.mine)
        {
            for(let item of breadcrumbs.value)
            {
                let toRoute = Object.values(Route).find(route => route.name === item.name);
    
                if(toRoute.mine)
                {
                    Object.assign(item, toRoute.mine);
                }
            }
        }
    });

    return breadcrumbs;
}