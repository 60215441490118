import { defineStore } from "pinia";

export const useNotificationStore = defineStore('notificationStore', 
{
    state: () =>
    ({
        notifications: new Map<symbol,string>(),
    }),

    actions:
    {
        notify(message: string):void
        {
            const key = Symbol();
            this.notifications.set(key, message);
            // This timeout ensures notifications aren't left around after they're hidden. Actually hiding the elements is handled by the library component
            setTimeout(() => { this.notifications.delete(key)}, 10_000);
        },
    }
});
