<template>
    <cr-table :items="page?.items" :headers="headers" :loading="isLoading" @sort="onSort" actionable class="cr-mb5" data-testing="saved-environment-access-table">
        <template v-slot:action="{item}">
            <cr-table-action-item v-if="showReshareAction(item)" :item="item" icon="bi-envelope" @click="onReShareAccessClicked" data-testing="saved-environment-access-table-re-share">
                {{ t('SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE') }}
            </cr-table-action-item>
            <cr-table-action-item v-if="showRemoveAccessAction(item)" :item="item" icon="bi-trash" @click="onRemoveAccessClicked" data-testing="saved-environment-access-table-remove-access">
                {{ t('SAVED_ENVIRONMENT_ACCESS_TABLE_REMOVE_ACCESS') }}
            </cr-table-action-item>
        </template>
    </cr-table>
    <cr-pagination :previous="!!page?.prevPageToken" :first="true" :next="!!page?.nextPageToken" @first="onLoadFirstPage" @previous="onLoadPreviousPage" @next="onLoadNextPage" :loading="isLoading"/>
    <remove-access-dialog v-model="showRemoveAccessDialog" :catalog-family-access="selectedItem" @confirm="onRemoveAccessConfirmed"/>
    <re-share-access-dialog v-model="showReShareAccessDialog" :catalog-family-access="selectedItem" @confirm="onReShareAccessConfirmed"/>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ITableHeaderItem, TableHeaderItem } from "@cyber-range/cyber-range-lib-ui";
import { capitalCase } from 'change-case';
import { useCalendar } from '../../composables/useCalendar';
import { IApiPageResponse, SortOrder } from '@cyber-range/cyber-range-api-client';
import { reactive, ref, toRaw, watch } from 'vue';
import { CatalogFamilyAccessFilter, CatalogFamilyAccessSortBy, CatalogFamilyAccessType, CatalogFamilyAccessVersion, ICatalogFamilyAccess } from '@cyber-range/cyber-range-api-catalog-client'
import Config from '../../config';
import { useCatalogStore } from '../../stores/catalogStore';
import { useEnum } from '../../composables/useEnum';
import RemoveAccessDialog from './dialogs/RemoveAccessDialog.vue'
import ReShareAccessDialog from './dialogs/ReShareAccessDialog.vue'
import { useAuthorizationStore } from '../../stores/authorizationStore';
import { useSessionStore } from '../../stores/sessionStore';
import { useNotificationStore } from '../../stores/notificationStore';

const catalogStore = useCatalogStore();
const currentUserId = useSessionStore().session?.userId;

const props = defineProps<
{
    catalogFamilyId: string;
}>();
const { t } = useI18n();

const formatVersion = (value: string) => useEnum().toDisplayEnumName(CatalogFamilyAccessVersion, value) || value;
const formatAccessStarted = (value: string) => value ? useCalendar().toHuman(value) : t('SAVED_ENVIRONMENT_ACCESS_TABLE_PENDING');

const headers = [
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENT_ACCESS_TABLE_NAME'), key: 'name', sortable: true }),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENT_ACCESS_TABLE_EMAIL'), key: 'email', sortable: true }),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENT_ACCESS_TABLE_ACCESS_TYPE'), key: 'accessType', sortable: true, formatter: capitalCase }),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENT_ACCESS_TABLE_VERSION'), key: 'version', sortable: true, formatter: formatVersion }),
    new TableHeaderItem({ text:t('SAVED_ENVIRONMENT_ACCESS_TABLE_ACCESS_START_DATE'), key: 'accessStartedTimestamp', sortable: true, formatter: formatAccessStarted, sortOrder: SortOrder.Desc}),
];

const page = ref<IApiPageResponse<ICatalogFamilyAccess>>();
const filter = reactive(new CatalogFamilyAccessFilter({limit: Config.SAVED_ENVIRONMENT_ACCESS_TABLE_PAGE_SIZE, sortBy: CatalogFamilyAccessSortBy.AccessStartedTimestamp, sortOrder: SortOrder.Desc}));
const isLoading = ref(false);

async function reload()
{
    if (page.value === undefined)
    {
        isLoading.value = true;
    }

    page.value = await catalogStore.listCatalogFamilyAccesses(props.catalogFamilyId, toRaw(filter));

    isLoading.value = false;
}
watch(filter, reload, { immediate: true });

async function onSort (header:ITableHeaderItem)
{
    filter.sortOrder = header.sortOrder as SortOrder;
    filter.sortBy = header.key?.toLowerCase() as CatalogFamilyAccessSortBy;
    filter.token = '';
}

function onLoadFirstPage()
{
    filter.token = '';
}

function onLoadPreviousPage()
{
    filter.token = page.value!.prevPageToken;
}

function onLoadNextPage()
{
    filter.token = page.value!.nextPageToken;
}

const selectedItem = ref<ICatalogFamilyAccess>();

function showReshareAction(access: ICatalogFamilyAccess): boolean
{
    return !access.accessStartedTimestamp;
}

function showRemoveAccessAction(access: ICatalogFamilyAccess): boolean
{
    const allowedTypes = [CatalogFamilyAccessType.Linked, CatalogFamilyAccessType.Owned];

    const canDeleteInOrganization = useAuthorizationStore().canDeleteCatalogFamilyAccesses(access.organizationId);
    const accessBelongsToMe = currentUserId === access.acceptorUserId;
    
    return allowedTypes.includes(access.accessType) && (canDeleteInOrganization || accessBelongsToMe);
}

const showRemoveAccessDialog = ref(false);

function onRemoveAccessClicked(item: ICatalogFamilyAccess)
{
    selectedItem.value = item;
    showRemoveAccessDialog.value = true;
}

function onRemoveAccessConfirmed()
{
    reload();
}

const showReShareAccessDialog = ref(false);

function onReShareAccessClicked(item: ICatalogFamilyAccess)
{
    selectedItem.value = item;
    showReShareAccessDialog.value = true;
}

function onReShareAccessConfirmed()
{
    showReShareAccessDialog.value = false;
    useNotificationStore().notify(t('SAVED_ENVIRONMENT_ACCESS_TABLE_RE_SHARE_ACCESS_NOTIFICATION'))
}
defineExpose({reload});
</script>